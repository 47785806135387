/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure contact details component.
 */

// Core dependencies
import React from "react";

// Styles
import { details } from "./contactDetails.module.css";

const classNames = require("classnames");

class ContactDetails extends React.Component {
  render() {
    const { className } = this.props;
    return (
      <div className={classNames(details, className)}>
        <p>We're here to help you start or expand your firm.</p>
        <p>Contact us today to help build your business.</p>
        <a href="tel:18445542367">(844) 5-LICENSE</a>
        <a
          href="mailto:info@licensesure.biz"
          rel="noopener noreferrer"
          target="_blank"
        >
          info@licensesure.biz
        </a>
      </div>
    );
  }
}

export default ContactDetails;
