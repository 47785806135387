/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure state guides component.
 */

// Core dependencies
import React from "react";

// App dependencies
import { MapUSAStaticQuery } from "../../hooks/mapUSAQuery";
import * as StateService from "../../utils/state.service";

// Styles
import { states } from "../form/form.module.css";
import { guides } from "./stateGuides.module.css";
import {
  centered,
  column,
  flex,
  sectionInner,
} from "../../styles/global.module.css";

const classNames = require("classnames");

export default function StateGuides() {
  const posts = MapUSAStaticQuery();
  const stateOptions = StateService.filterListStates(posts);
  return (
    <section className={guides}>
      <div className={classNames(sectionInner, flex, column, centered)}>
        <h2>State Guides</h2>
        <p>
          Find information and links to resources on licensing guidelines for
          each state:
        </p>
        <form className={states}>
          <select onChange={redirectToStatePage(posts)}>
            <option value="select" label="Select State">
              Select State
            </option>
            {stateOptions &&
              stateOptions.map(({ name, state }) => (
                <option key={state} value={state}>
                  {name}
                </option>
              ))}
          </select>
        </form>
      </div>
    </section>
  );
}

/**
 * Redirects to corresponding state page.
 * @param posts
 * @returns {function(*)}
 */
function redirectToStatePage(posts) {
  return (event) => {
    const state = event.target.value.toUpperCase();
    StateService.showStatePage(posts, state);
  };
}
