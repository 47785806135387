/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure - home page.
 */

// Core dependencies
import React from "react";

// App dependencies
import Home from "../components/home/home";
import Layout from "../components/layout";

const IndexPage = () => (
  <Layout homePage>
    <Home />
  </Layout>
);

export default IndexPage;
