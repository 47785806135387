/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure map component.
 */

// Core dependencies
import React from "react";

// App dependencies
import { MapUSAStaticQuery } from "../../hooks/mapUSAQuery";
import * as StateService from "../../utils/state.service";
import MapUSA from "./mapUSA.svg";

// Styles
import { map, hover } from "./mapUSA.module.css";
import {
  centered,
  column,
  flex,
  sectionInner,
} from "../../styles/global.module.css";

const classNames = require("classnames");

class Map extends React.Component {
  componentDidMount() {
    this.setStateInteractionsAndAttributes();
  }

  componentWillUnmount() {
    this.removeStateInteractionAndAttributes();
  }

  clearStateClass = (stateEl) => {
    return () => {
      // Get corresponding state element
      const correspondingStateEl = this.getCorrespondingStateEl(stateEl);

      // Remove class and filter attributes
      stateEl.removeAttribute("class");
      correspondingStateEl.removeAttribute("class");
    };
  };

  getCorrespondingStateEl = (stateEl) => {
    const statePrefix = this.getStateIdArray(stateEl)[0];
    const stateNumber = this.getStateIdArray(stateEl)[1];
    const prefix = statePrefix === "s" ? "l" : "s";

    return document.getElementById(`${prefix}-${stateNumber}`);
  };

  getStateIdArray = (stateEl) => {
    const stateId = stateEl.attributes.id.value;

    return stateId.split("-");
  };

  redirectToStatePage = (stateEl) => {
    return () => {
      const stateNumber = this.getStateIdArray(stateEl)[1];
      const state = StateService.filterStateYAMLByStateNumber(stateNumber);
      const { posts } = this.props;

      StateService.showStatePage(posts, state);
    };
  };

  registerStateHoverAndClickHandler = (stateEls) => {
    stateEls.forEach((stateEl) => {
      stateEl.addEventListener(
        "click",
        this.redirectToStatePage(stateEl),
        false
      );
      stateEl.addEventListener("mouseenter", this.setStateClass(stateEl));
      stateEl.addEventListener("mouseleave", this.clearStateClass(stateEl));
    });
  };

  removeStateHoverAndClickHandler = (stateEls) => {
    stateEls.forEach((stateEl) => {
      stateEl.removeEventListener(
        "click",
        this.redirectToStatePage(stateEl),
        false
      );
      stateEl.removeEventListener("mouseenter", this.setStateClass(stateEl));
      stateEl.removeEventListener("mouseleave", this.clearStateClass(stateEl));
    });
  };

  removeStateInteractionAndAttributes = () => {
    const pathEls = document.querySelectorAll("path[id^=s-]");
    const textEls = document.querySelectorAll("text[id^=l-]");

    // Remove all event listeners for state path and text elements
    this.removeStateHoverAndClickHandler(pathEls);
    this.removeStateHoverAndClickHandler(textEls);
  };

  setStateClass = (stateEl) => {
    return () => {
      // Get corresponding state element
      const correspondingStateEl = this.getCorrespondingStateEl(stateEl);

      // Add class and filter attributes
      stateEl.setAttribute("class", hover);
      correspondingStateEl.setAttribute("class", hover);
    };
  };

  setStateInteractionsAndAttributes = () => {
    const pathEls = document.querySelectorAll("path[id^=s-]");
    const textEls = document.querySelectorAll("text[id^=l-]");

    // Add event listener for mouse over and mouse out and click event of state path elements
    this.registerStateHoverAndClickHandler(pathEls);

    // Add event listener for mouse over and mouse out and click event of state text elements that appear outside of map
    // State text elements within state path have pointer event "none" class applied
    this.registerStateHoverAndClickHandler(textEls);
  };

  render() {
    return (
      <section className={map} id="map">
        <div className={classNames(sectionInner, flex, column, centered)}>
          <h2>Research Licensing and Qualification Requirements</h2>
          <h3>
            Select a state below to find links to the regulatory authority
            websites for your profession and other state-specific information.
          </h3>
          <MapUSA />
        </div>
      </section>
    );
  }
}

const InteractiveMap = () => {
  return <Map posts={MapUSAStaticQuery()} />;
};

export default InteractiveMap;
