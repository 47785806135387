import { useStaticQuery, graphql } from "gatsby";

export const QuickLinksStaticQuery = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query QuickLinksStaticQuery {
        allMarkdownRemark {
          edges {
            node {
              htmlAst
              frontmatter {
                component
                linked {
                  childMarkdownRemark {
                    htmlAst
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark.edges
    .map((e) => e.node)
    .filter(
      (n) => n.frontmatter.component && n.frontmatter.component === "quickLinks"
    )[0];
};
