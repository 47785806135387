import { useStaticQuery, graphql } from "gatsby";

export const MapUSAStaticQuery = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query MapUSAStaticQuery {
        allMarkdownRemark {
          edges {
            node {
              fields {
                slug
              }
              html
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark.edges
    .map((e) => e.node)
    .filter((n) => n.fields.slug && n.fields.slug.includes("/map-usa/"));
};
