/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure contact us component.
 */

// Core dependencies
import React from "react";

// App dependencies
import Form from "../form/form";
import ContactDetails from "./contactDetails";

// Styles
import { bgDark } from "./contactDetails.module.css";
import { contactUs } from "./contactUs.module.css";
import { centered, flex, stretch } from "../../styles/global.module.css";

const classNames = require("classnames");

class ContactForm extends React.Component {
  render() {
    const { className, state, tagHeading, text, title } = this.props;
    const heading = title ? title : "Contact Us";
    const TagHeading = tagHeading ? tagHeading : "h2";
    const detailsClassName =
      (className || "").indexOf("bgDark") >= 0 ? bgDark : "";
    return (
      <>
        <TagHeading>{heading}</TagHeading>
        {text ? <p>{text}</p> : null}
        <div className={classNames(centered, contactUs, flex, stretch)}>
          <Form className={className} stateValue={state} />
          <ContactDetails className={detailsClassName} />
        </div>
      </>
    );
  }
}

export default ContactForm;
