/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure form component.
 */

// Core dependencies
import React from "react";

// Styles
import {
  button as buttonStyles,
  contact,
  error as errorStyles,
  submitted as submittedStyles,
  primary,
} from "./form.module.css";

const classNames = require("classnames");

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailError: false,
      commentError: false,
      submitted: false,
    };
  }

  submit = (e) => {
    e.preventDefault();

    const { stateValue } = this.props;
    const data = new FormData(e.target);
    const contactTypeValue = stateValue ? "State Breakdown" : "Contact Form";
    data.append("contactType", contactTypeValue);

    if (stateValue) {
      data.append("state", stateValue);
    }

    const emailError = !data.get("email");
    const commentError = !data.get("comment");

    if (emailError) {
      this.setState({
        emailError: true,
      });
    }

    if (commentError) {
      this.setState({
        commentError: true,
      });
    }

    if (emailError || commentError) {
      return;
    }

    this.setState({
      submitted: true,
    });

    var object = {};
    data.forEach((value, key) => {
      object[key] = value;
    });
    var json = JSON.stringify(object);

    fetch("https://api.licensesure.biz/v1/contact-us", {
      method: "POST",
      body: json,
      mode: "no-cors",
    });
  };

  render() {
    const { className } = this.props;
    return (
      <>
        {this.state.submitted ? (
          <p className={classNames(className, submittedStyles)}>
            Thank you, we'll be in contact shortly!
          </p>
        ) : (
          <form
            className={classNames(className, contact)}
            onSubmit={this.submit}
            noValidate
          >
            <input
              name="email"
              placeholder="Email Address *"
              required
              type="email"
              className={classNames({ [errorStyles]: this.state.emailError })}
            />
            <textarea
              name="comment"
              placeholder="Message *"
              required
              type="text"
              className={classNames({ [errorStyles]: this.state.commentError })}
            />
            <button className={classNames(buttonStyles, primary)} type="submit">
              Submit
            </button>
          </form>
        )}
      </>
    );
  }
}

export default ContactForm;
