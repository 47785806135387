/*
 * LicenseSure
 * https://licensesure.biz
 *
 * The LicenseSure homepage component.
 */

// Core dependencies
import { Link } from "gatsby";
import React from "react";
import { isMobileOnly } from "react-device-detect";

// App dependencies
import ContactUs from "../contact/contactUs";
import Map from "../map/mapUSA";
import QuickLinks from "../quickLinks/quickLinks";
import StateGuides from "../state/stateGuides";

// Styles
import { bgDark, contactUs } from "../form/form.module.css";
import { contact, headline, helps, hero, serves, sub } from "./home.module.css";
import {
  centered,
  column,
  flex,
  sectionInner,
} from "../../styles/global.module.css";

let classNames = require("classnames");

class Home extends React.Component {
  render() {
    return (
      <>
        <section className={hero}>
          <div className={classNames(sectionInner, flex, column, centered)}>
            <h1 className={headline}>
              LicenseSure helps design professionals navigate complex licensing
              and qualification regulations in every state
            </h1>
            <Link to="/services">See Our Services</Link>
          </div>
        </section>
        <section className={serves}>
          <div className={classNames(sectionInner, flex, column)}>
            <div>
              <div className={sub}>LicenseSure Serves</div>
              <p>
                Architects, Engineers, Landscape Architects, Land Surveyors and
                Geologists.
              </p>
            </div>
          </div>
        </section>
        <section className={helps}>
          <div className={classNames(sectionInner, flex, column)}>
            <div>
              <div className={sub}>LicenseSure Helps You</div>
              <p>
                Be sure you are properly licensed in every state you do
                business.
              </p>
              <p>Never miss a licensing or renewal deadline.</p>
              <p>Expand into new states quickly and accurately.</p>
            </div>
          </div>
        </section>
        <QuickLinks />
        {isMobileOnly ? <StateGuides /> : <Map />}
        <section className={contact}>
          <div className={classNames(sectionInner, flex, column, centered)}>
            <ContactUs className={classNames(contactUs, bgDark)} />
          </div>
        </section>
      </>
    );
  }
}

export default Home;
