/*
 * LicenseSure
 * https://licensesure.biz
 *
 * Basic state map and guide service.
 */

// Imports
import { navigate } from "gatsby";
import mapYAML from "../../content/map-usa/mapUSA.yaml";

/**
 * Returns filtered list of states with corresponding markdown pages.
 *
 * @param posts
 */
export function filterListStates(posts) {
  const listAvailableStatePostsByState = posts.map((post) =>
    getPostStateAcronym(post)
  );

  if (!listAvailableStatePostsByState) {
    return;
  }

  return mapYAML.filter((state) => {
    return listAvailableStatePostsByState.some((postState) => {
      return postState === state.state.toUpperCase();
    });
  });
}

/**
 * Returns the filtered state id name from mapYAML specified by state number.
 *
 * @param stateNumber
 * @returns {string}
 */
export function filterStateYAMLByStateNumber(stateNumber) {
  const state = mapYAML.filter((state) => state.id === stateNumber)[0];

  if (!state) {
    return "";
  }

  return state.state;
}

/**
 * Returns post slug state acronym.
 *
 * @param state
 * @returns {*}
 */
export function getPostStateAcronym(state) {
  if (!state) {
    return "";
  }

  if (!state.fields) {
    return "";
  }

  return getSlugStateAcronym(state.fields.slug);
}

/**
 * Returns slug's state acronym.
 *
 * @param slug
 * @returns {*}
 */
export function getSlugStateAcronym(slug) {
  if (!slug) {
    return "";
  }

  // Slug exists, and should be "/map-usa/ACRONYM"
  const splitSlug = slug.split("/");

  if (!splitSlug) {
    return "";
  }

  const slugLength = splitSlug.length;

  return slug.split("/")[slugLength - 1].toUpperCase();
}

/**
 * Returns corresponding state name specified by state acronym.
 *
 * @param acronym
 */
export function getStateName(acronym) {
  const state = mapYAML.find((state) => state.state === acronym);

  if (!state) {
    return "";
  }

  return state.name;
}

/**
 * Redirects to corresponding state page.
 *
 * @param posts
 * @param state
 */
export function showStatePage(posts, state) {
  if (!posts) {
    return;
  }
  const post = posts.filter((post) => getPostStateAcronym(post) === state)[0];
  if (!post) {
    return;
  }
  navigate(post.fields.slug);
}
