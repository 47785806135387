/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure quick links component.
 */

// Core dependencies
import React from "react";

// App dependencies
import { QuickLinksStaticQuery } from "../../hooks/quickLinksQuery";
import Markdown from "../markdown/markdown";

// Styles
import {
  alignSelf,
  quickLink,
  quickLinks,
  tiles,
} from "./quickLinks.module.css";
import {
  between,
  bgLight,
  centered,
  column,
  flex,
  sectionInner,
} from "../../styles/global.module.css";

const classNames = require("classnames");

export default function QuickLinks() {
  const post = QuickLinksStaticQuery();
  const { htmlAst, frontmatter } = post;
  const { linked } = frontmatter;
  return (
    <section className={classNames(bgLight, quickLinks)}>
      <div className={classNames(sectionInner, flex, column, centered)}>
        <Markdown className={alignSelf}>{htmlAst}</Markdown>
        <div className={classNames(flex, between, tiles)}>
          {linked
            ? linked.map((p, i) => (
                <Markdown key={i} className={quickLink}>
                  {p.childMarkdownRemark.htmlAst}
                </Markdown>
              ))
            : null}
        </div>
      </div>
    </section>
  );
}
